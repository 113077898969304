import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCode } from "../utils/StatusCode";

export const articleController = new AbortController();

/** Define the inidial state of Redux store for specific article-related slice */
const initialState = {
    data: [],
    article: null, // initially no article
    status: 'idle', // async operation status
    error: null, // no errors
  };

  /** Create Redux Slice that manages this state */

  const articleSlice = createSlice({
    name: 'articles', // set the slice name to articles
    initialState, // initial state of the slice
    reducers: {}, // Define synchronous Redux actions
    extraReducers: (builder) => {
        builder
      // Asynchronous actions w/ side effects. Below handle specific cases
      .addCase(fetchArticle.pending, (state, action) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(fetchArticle.fulfilled, (state, action) => {
        state.status = StatusCode.IDLE;
        state.article = action.payload.article; // update state.article data
        state.error = null; // reset error
      })
      .addCase(fetchArticle.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
        state.error  = action.error.message;
      })
    }
  });

  /** exports reducer function responsible for updating the articleSlice of Redux store. */
  export default articleSlice.reducer;

  /** Create the async thunk
   * -- Thunks: dispatched to perform async logic
   */

    export const fetchArticle = createAsyncThunk(
    // type string, describes action being performed
    'articles/get',
    //Property that takes 3 params. 
    async ({ year, make, model }) => {
        // Debugging
        console.log(year, "Year");
        console.log(`Make: ${make}`);
      try {
        // Can search based off of a variety of options. Add to the url?
        let API_KEY = process.env.REACT_APP_PUBLIC_DRIVE_CHICAGO_API_KEY;
        let queryParams = `?apiKey=${API_KEY}&rows=5`;
        if (year) queryParams += `&year=${year}`;
        if (make) queryParams += `&make=${make}`;
        if (model) queryParams += `&model=${model}`;

        const response = await fetch(
          `https://api.drivechicago.cloud/articles/byYearMakeModel${queryParams}`
        );
        if (!response.ok) {
          // Response failed; could not fetch data
          throw new Error("Failed to fetch article");
        }
        const data = await response.json();
        // No article found
        if (!data.article) {
          throw new Error("Article not found");
        }
        // Extract relevant information from the response
        const article = {
          year: data.article.year,
          make: data.article.make,
          model: data.article.model,
          title: data.article.title,
          body: data.article.body,
          lastModifiedDate: data.article.lastModifiedDate,
          thumbnailUrl: data.article.thumbnailUrl,
        };

        const author = {
          name: data.author.name,
          bio: data.author.bio,
          website: data.author.website,
          pic: data.author.pic,
          byLine: data.author.byLine,
          sortOrder: data.author.sortOrder,
        };
        return { article, author };
      } catch (error) {
        throw new Error('Failed to fetch article');
      }
    }
  );