import React from "react";
import { Link } from "react-router-dom";
import ToyotaBanner from "../images/Banner-Ad-Toyota.jpg";
import ToyotaVertical from "../images/Toyota-Vertical-Ad.jpg";
import FordVertical from "../images/Ford-Vertical-Ad.jpg";
import FordBanner from "../images/Banner-Ad-Ford.jpg";
import "./ArticleHome.css";

export default function ArticleHome() {
    <>
      <div className="article-banner-ad-wrapper">
        <Link className="article-banner-ad" to="/">
          <img
            className="article-banner-ad-image"
            src={ToyotaBanner}
            alt="placeholder"
          />
        </Link>
      </div>
      <h1 className="article-home-title">Reviews and Road Tests</h1>
      <div className="article-home-content-wrapper">
        <div className="article-home-vertical-ad-wrapper">
          <Link>
            <img src={ToyotaVertical} alt="ad" />
          </Link>
        </div>
        <div className="article-home-content">
          
        </div>
        <div className="article-vertical-ad-wrapper">
          <Link>
            <img src={FordVertical} alt="ad" />
          </Link>
        </div>
      </div>
      <div className="article-banner-ad-wrapper">
          <Link className="article-banner-ad" to="/">
            <img
              className="article-banner-ad-image"
              src={FordBanner}
              alt="placeholder"
            />
          </Link>
        </div>
    </>;
}