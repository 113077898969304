export const mileage = [{
    label: "0 - 10,000",
    value: "0-10000"
},
{
    label: "10,001 - 20,000",
    value: "10001-20000"
},
{
    label: "20,001 - 30,000",
    value: "20001-30000"
},
{
    label: "30,001 - 40,000",
    value: "30001-40000"
},
{
    label: "40,001 - 50,000",
    value: "40001-50000"
},
{
    label: "50,001 - 60,000",
    value: "50001-60000"
},
{
    label: "60,001 - 70,000",
    value: "60001-70000"
},
{
    label: "70,001 - 80,000",
    value: "70001-80000"
},
{
    label: "80,001 - 90,000",
    value: "80001-90000"
},
{
    label: "90,001 - 100,000",
    value: "90001-100000"
},
{
    label: "100,001+",
    value: "100000-3000000"
}
];
