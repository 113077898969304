const PathConstants = {
    HOME: "/",
    ABOUT: "/about",
    CAR_SEARCH: "/car-search",
    CAR_SHOWS: "/car-shows",
    LISTINGS_CAR_SHOWS: "/car-shows/create-listing",
    REVIEWS: "/reviews",
    SELL_CAR: "/sell-car",
    LISTINGS_DEALER: "/dealer-listings",
    CALCULATOR: "/calculator",
    SIGNUP: "/signup",
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot-password",
    LISTINGS_CITY: "/city-listings",
    LISTINGS_MAKE: "/make-listings",
    ARTICLE_HOME: "/articles",
    ARTICLE: "/article",
    VEHICLE: "/vehicle/:vin",
    RESULTS: "/results",
    ACCOUNT_SETTINGS: "/account-settings",
    SAVED_CARS: "/saved-cars",
    SAVED_SEARCHES: "/saved-searches",
    GARAGE: "/garage",
    EMAIL_NOTIFICATIONS: "/email-notifications",
    LISTING_STEP_ONE: "/listing-step-one",
    LISTING_STEP_TWO: "/listing-step-two",
    LISTING_STEP_THREE: "/listing-step-three",
    LISTING_STEP_FOUR: "/listing-step-four",
    LISTING_STEP_FIVE: "/listing-step-five",
    LISTING_STEP_SIX: "/listing-step-six",
    LISTING_CONFIRMATION: "/listing-confirmation",
    FAQ: "/faq",
    CONTACT: "/contact",
    PRIVACY_POLICY: "/privacy-policy",
    TERMS_OF_USE: "/terms-of-use",
    NOT_FOUND: "*"
}

export default PathConstants