export const engines = [
  "0.6L I2", "0.7L I2", "1.0L I3", "1.2L I3", "1.2L I4", "1.3L I3", "1.3L I4", "1.4L I4", "1.5L I3", "1.5L I4",
  "1.6L I3", "1.6L I4", "1.7L I4", "1.8L I4", "1.9L I4", "2.0L H4", "2.0L I4", "2.1L I4", "2.2L I4", "2.3L I4",
  "2.4L H4", "2.4L I4", "2.5L H4", "2.5L I4", "2.5L I5", "2.5L I6", "2.5L V6", "2.7L H6", "2.7L I4", "2.7L V6",
  "2.8L I4", "2.8L I6", "2.8L V6", "2.9L I4", "2.9L V6", "2L I4", "3.0L H6", "3.0L I4", "3.0L I6", "3.0L V6",
  "3.0L V8", "3.1L V6", "3.2L H6", "3.2L I5", "3.2L I6", "3.2L V6", "3.3L I6", "3.3L V6", "3.4L H6", "3.4L V6",
  "3.5L I4", "3.5L I5", "3.5L V6", "3.5L V8", "3.6L H6", "3.6L I4", "3.6L V6", "3.7L I5", "3.7L V6", "3.8L H6",
  "3.8L V6", "3.8L V8", "3.9L V6", "3.9L V8", "3L I6", "3L V6", "4.0L H6", "4.0L V6", "4.0L V8", "4.2L I6",
  "4.2L V6", "4.2L V8", "4.3L V6", "4.3L V8", "4.4L V8", "4.5L V8", "4.6L V8", "4.7L V8", "4.8L V8", "4.9L V8",
  "4L I6", "4L V6", "4L V8", "5.0L V8", "5.2L I4", "5.2L V10", "5.2L V12", "5.2L V8", "5.3L V8", "5.4L V8",
  "5.5L V8", "5.6L V8", "5.7L V10", "5.7L V12", "5.7L V8", "5.8L V8", "5.9L I6", "5.9L V12", "5.9L V8", "5L V8",
  "6.0L V12", "6.0L V8", "6.0L W12", "6.1L V8", "6.2L V8", "6.4L V8", "6.5L V12", "6.5L V8", "6.6L V12", "6.6L V8",
  "6.7L I6", "6.7L V12", "6.7L V8", "6.8L V10", "6.8L V8", "6L V12", "6L V8", "6L W12", "7.0L V8", "7.3L V8",
  "8.3L V10", "8.4L V10"
];