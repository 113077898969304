export const SellCarQuestions = [
    {
        "id": 0,
        "question": "When will I receive an estimate for my vehicle?",
        "answer": "Update answer"
    },
    {
        "id": 1,
        "question": "What documents do I need to sell my vehicle?",
        "answer": "Update answer"
    },
    {
        "id": 2,
        "question": "What should I do to prepare my vehicle to sell?",
        "answer": "Update answer"
    },
    {
        "id": 3,
        "question": "Can I receive a second opinion on my vehicle's value?",
        "answer": "Update answer"
    },
]