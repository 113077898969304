export const prices = [{
    label: "$0 - $10,000",
    value: "0-10000"
  },
  {
    label: "$10,001 - $15,000",
    value: "10001-15000"
  },
  {
    label: "$15,001 - $20,000",
    value: "15001-20000"
  },
  {
    label: "$20,001 - $30,000",
    value: "20001-30000"
  },
  {
    label: "$30,001 - $40,000",
    value: "30001-40000"
  },
  {
    label: "$40,001 - $50,000",
    value: "40001-50000"
  },
  {
    label: "$50,001 - $75,000",
    value: "50001-75000"
  },
  {
    label: "$75001 - $100,000",
    value: "75001-100000"
  },
  {
    label: "$100,000+",
    value: "100001-3000000"
  }];