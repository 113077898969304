export const vehicles = [
    "Acura",
    "Audi",
    "BMW",
    "Cadillac",
    "Chevrolet",
    "Dodge",
    "Ford",
    "Honda",
    "Hyundai",
    "Jeep",
    "Mercedes-Benz",
    "Nissan",
    "Toyota",
    "Volkswagen"
]