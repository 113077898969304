import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store/store';
import App from 'src/App.js';
// import { PublicClientApplication, EventType } from '@azure/msal-browser';
// import { msalConfig } from './auth-config';
import 'src/index.css';

// export const msalInstance = new PublicClientApplication(msalConfig);

// if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0])
//};

//msalInstance.addEventCallback((event) => {
//  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
//    const account = event.payload.account;
//    msalInstance.setActiveAccount(account);
//  }
//});

// <App instance={msalInstance} />

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <App />
      </Provider>
  </React.StrictMode>
);
